import React from "react"
import styled from "styled-components"
import { darken, transparentize } from "polished"

import { NavLink } from "../Links"
import { getDuration } from "../style"

export const CardContainer = styled.section`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  align-items: stretch;
  perspective: 200px;
  transform-style: preserve-3d;
`

export const Card = styled.div`
  position: relative;
  box-sizing: border-box;
  z-index: -1;
  padding: 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.125rem;

  background-color: white;
  transition: transform ${getDuration(0.2)} ease-in ${getDuration(0.075)};
  transform-style: preserve-3d;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.25rem;
    right: 0.25rem;
    z-index: 2;

    border-radius: 0.25rem;
    box-shadow: 0 2px 4px
        ${({ theme: { colors } }) =>
          transparentize(0.8, darken(0.25, colors.brand))},
      0 4px 8px
        ${({ theme: { colors } }) =>
          transparentize(0.85, darken(0.25, colors.brand))},
      0 8px 16px
        ${({ theme: { colors } }) =>
          transparentize(0.9, darken(0.25, colors.brand))},
      0 16px 32px
        ${({ theme: { colors } }) =>
          transparentize(0.95, darken(0.25, colors.brand))},
      0 32px 64px
        ${({ theme: { colors } }) =>
          transparentize(0.9, darken(0.25, colors.brand))};

    opacity: 0;
    transition: ${getDuration(0.2)} ease-in ${getDuration(0.075)};
    transform: scale(0.985) translateZ(-0.05rem);
  }
  &:hover {
    border-color: ${({ theme: { colors } }) =>
      transparentize(0.95, darken(0.25, colors.brand))};
    transform: scale(1.01) translateY(-0.125rem) translateZ(0.05rem);
    transition: transform ${getDuration(0.125)} ease-out;
    z-index: 1;

    &::after {
      opacity: 1;
      /* z-index: 1; */
      transition: opacity ${getDuration(0.125)} ease-out;
    }
  }
`

const CardGrid = ({ entries }) => (
  <CardContainer>
    {entries.map(({ node: { name, description, count, fields: { slug } } }) => (
      <Card key={slug}>
        <h3>
          <NavLink to={slug}>{name}</NavLink>
        </h3>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <small>Articles: {count}</small>
      </Card>
    ))}
  </CardContainer>
)

export default CardGrid
