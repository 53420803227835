import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const IssueDisplay = ({
  title,
  slug,
  date,
  excerpt,
  featured_media,
  categories,
}) => (
  <article>
    <em>Edition {categories[0] && categories[0].acf.edition_display}</em>
    <h2>
      <Link
        to={`/${categories[0] && categories[0].slug}/${slug}`}
        style={{ textDecoration: "none" }}
      >
        {title}
      </Link>
    </h2>
    <Img fluid={featured_media.localFile.childImageSharp.fluid} />
    <div dangerouslySetInnerHTML={{ __html: excerpt }} />
    <span>
      {new Date(date).getMonth()} / {new Date(date).getFullYear()}
    </span>
  </article>
)

export default IssueDisplay
