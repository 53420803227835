import React from "react"

import IssueDisplay from "./IssueDisplay"
import IssueList from "./IssueList"

const IssueSection = ({ issues = [] }) => (
  <>
    <small>Our latest issue</small>
    {issues.length ? (
      <IssueDisplay {...issues[0].node} />
    ) : (
      <p>No current issue</p>
    )}
    <hr />
    <IssueList issues={issues.slice(1)} />
  </>
)

export default IssueSection
