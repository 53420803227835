import React from "react"
import { Link } from "gatsby"

const IssueList = ({ issues }) => {
  if (!issues.length) return <small>No more issues</small>
  return (
    <ul>
      {issues.map(({ node }) => (
        <li key={node.slug}>
          <em>
            Edition{" "}
            {node.categories[0] && node.categories[0].acf.edition_display}
          </em>
          <h3>
            <Link
              to={`/${node.categories[0] && node.categories[0].slug}/${
                node.slug
              }`}
              style={{ textDecoration: `none` }}
            >
              {node.title}
            </Link>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          <span>{node.date}</span>
        </li>
      ))}
    </ul>
  )
}

export default IssueList
