import React, { useReducer, useState } from "react"

import addToMailchimp from "../../services/api"
import NewsletterFormDisplay from "./NewsletterFormDisplay"

const INITIAL_STATE = {
  message: null,
  isLoading: false,
  hasError: false,
  isSubscribed: false,
}

const newsletterReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_UP_REQUEST":
      return { ...state, isLoading: true }
    case "SIGN_UP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isSubscribed: true,
        hasError: false,
        message: null,
        preferencesUrl: action.payload.preferencesUrl,
      }
    case "SIGN_UP_FAILURE":
      return {
        ...state,
        isLoading: false,
        isSubscribed: false,
        hasError: true,
        message: action.payload.message,
        preferencesUrl: action.payload.preferencesUrl,
      }
    case "SET_ERROR":
      return {
        ...state,
        hasError: action.hasError,
      }
    case "SUBMIT_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError: true,
        message: action.payload.message,
      }
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.message,
      }
    default:
      return state
  }
}

const NewsletterFormContainer = () => {
  const [
    { hasError, isLoading, isSubscribed, message, preferencesUrl },
    dispatch,
  ] = useReducer(newsletterReducer, INITIAL_STATE)
  const [email, setEmail] = useState("")
  const [touched, setTouched] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const handleBlur = () => setTouched(true)
  const handleChange = event => {
    setEmail(event.target.value)

    const validity = event.target.checkValidity()
    setIsValid(validity)

    if (validity === false) {
      dispatch({
        type: "SET_MESSAGE",
        message:
          event.target.validationMessage ||
          "Please enter a valid email address.",
      })
    } else {
      dispatch({ type: "SET_ERROR", hasError: false })
      dispatch({ type: "SET_MESSAGE", message: null })
    }
  }
  const handleInvalid = event => {
    setIsValid(false)
    setTouched(true)
    dispatch({ type: "SET_ERROR", hasError: true })
    dispatch({
      type: "SET_MESSAGE",
      message:
        event.target.validationMessage || "Please enter a valid email address.",
    })
  }
  const handleSubmit = async event => {
    event.preventDefault()
    dispatch({ type: "SIGN_UP_REQUEST" })

    try {
      const response = await addToMailchimp(email, false, true)
      console.log("handleSubmit", "response", response)

      if (response.status >= 300) {
        dispatch({ type: "SIGN_UP_FAILURE", payload: response.info })
      } else {
        dispatch({ type: "SIGN_UP_SUCCESS", payload: response.info })
      }
      setTouched(false)
    } catch (error) {
      console.warn("handleSubmit", "error", error)
      const message = "Sign up api could not be reached"
      dispatch({ type: "SIGN_UP_FAILURE", payload: { message } })
      setTouched(false)
    }
  }

  return (
    <NewsletterFormDisplay
      email={email}
      message={message}
      preferencesUrl={preferencesUrl}
      hasError={hasError}
      isSubscribed={isSubscribed}
      isLoading={isLoading}
      isValid={isValid}
      touched={touched}
      onBlur={handleBlur}
      onChange={handleChange}
      onInvalid={handleInvalid}
      onSubmit={handleSubmit}
    />
  )
}

export default NewsletterFormContainer
