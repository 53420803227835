import React from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import { transparentize, readableColor, linearGradient, shade } from "polished"
import { Link } from "gatsby"

import Icon from "../Icon"

const Form = styled.form`
  ${({ theme: { colors, spacing } }) => css`
    ${linearGradient({
      colorStops: [`${colors.accent} 60%`, `${shade(0.2, colors.accent)} 100%`],
      toDirection: "to bottom",
    })};
    color: ${readableColor(colors.accent, colors.dark, colors.light)};
    /* outline: 1em dotted white;
    outline-offset: -0.45em;
    border-left: 1em solid white;
    border-right: 1em solid white; */
    border-radius: 0.125em;
    margin-left: -1em;
    margin-right: -1em;
    padding: ${spacing.md} ${spacing.default};

    ${up("sm")} {
      padding: ${spacing.lg} ${spacing.md};
    }

    ${up("md")} {
      padding: ${spacing.xl} ${spacing.xxl};
      /* border-left: none;
      border-right: none;
      border-bottom: 1em solid white;
      border-top: 1em solid white; */
    }

    h1 {
      margin-top: 0;
    }

    fieldset {
      border: none;
      margin-top: ${spacing.default};
      padding: 0;
    }

    legend {
      font-size: ${spacing.default};
      margin: 0;
      margin-bottom: ${spacing.default};
      padding: 0;
    }
  `}
`

const Message = styled.p`
  color: ${({ theme: { colors } }) =>
    readableColor(colors.accent, colors.dark, colors.light)};
  word-break: break-word;

  ${({ variant, theme: { colors } }) => {
    if (variant === "error")
      return css`
        color: ${colors.error};
      `
    if (variant === "success")
      return css`
        color: ${colors.success};
      `
  }}
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: calc(100% - 40px) center;
`

const Input = styled.input`
  display: block;
  padding: ${({ theme: { spacing } }) => spacing.xs};
  margin: ${({ theme: { spacing } }) => spacing.xs} 0;
  width: 100%;
  background: ${transparentize(0.8, "#fff")};
  border: 2px solid transparent;
  border-radius: 3px;
  font-family: inherit;
  font-size: 1em;
  color: white;
  height: 2.5rem;
  transition: background 0.45s ease-out, color 0.25s ease-out,
    box-shadow 0.45s ease-out;
  box-shadow: none;

  &:focus {
    outline: none;
    background-color: transparent;
    background: ${transparentize(0.95, "white")};
    box-shadow: 0 0 0 0.125rem ${transparentize(0.25, "white")};
  }

  ${({ touched, theme: { colors } }) =>
      touched &&
      css`
        &:valid {
          color: ${colors.success};
          background: ${transparentize(0.95, colors.success)};
          box-shadow: 0 0 0 0.125rem ${transparentize(0.25, colors.success)};
        }
        &:invalid {
          color: ${colors.error};
          background: ${transparentize(0.95, colors.error)};
          box-shadow: 0 0 0 0.125rem ${transparentize(0.25, colors.error)};
        }
      `}
    ::-moz-selection {
    color: inherit;
    background-color: white;
  }
  ::selection {
    color: inherit;
    background-color: white;
  }
`

const CombiInputContainer = styled.div`
  display: grid;
  grid-template-rows: 2.5rem 3.5rem;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;

  margin: ${({ theme: { spacing } }) => spacing.xs} 0;

  input {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 1;

    height: 100%;
    margin: 0;
    padding-top: 0;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
  }

  input + svg {
    grid-row: 1;
    grid-column: 1;
    align-self: baseline;
    justify-self: end;

    margin: ${({ theme: { spacing } }) => spacing.xs};

    transition: stroke 0.5s ease-in;
  }

  input + svg + button {
    display: block;
    grid-row: 2;
    grid-column: 1;

    width: auto;
    margin: ${({ theme: { spacing } }) => spacing.xxs};
  }

  ${up("md")} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 10rem;

    input {
      grid-row: 1;
      grid-column: 1 / 3;

      padding: ${({ theme: { spacing } }) => spacing.xs};
      padding-right: 12.5rem;
    }

    input + svg {
      align-self: center;
    }

    input + svg + button {
      grid-row: 1;
      grid-column: 2;
    }
  }
`

const SubmitButton = styled.button`
  background-color: white;
  border: 0.15rem solid white;

  color: black;
  border-radius: 0.15rem;
  line-height: 1.5;
  height: 2.5rem;
  font-size: ${({ theme: { spacing } }) => spacing.sm};
  text-transform: uppercase;
  cursor: pointer;

  transition: border 0.5s ease-out, background-color 0.5s ease-out,
    transform 0.25s ease-in 0.15s;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: 0 0.15rem 0 ${({ theme: { colors } }) => colors.dark};

    transform: translateY(-0.1rem);
    transition: border 0.5s ease-out, background-color 0.5s ease-out,
      transform 0.1s ease-out;
  }
  &:active:not(:disabled) {
    box-shadow: 0 -0.15rem 0 ${({ theme: { colors } }) => colors.dark};

    transform: translateY(0.1rem);
    transition: border 0.5s ease-out, background-color 0.5s ease-out,
      transform 0.1s ease-out;
  }
  &:disabled {
    background-color: transparent;
    color: white;
    border: 0.15rem solid rgba(255, 255, 255, 0.5);

    cursor: not-allowed;
  }
`

const EmailInput = ({ message, hasError, isSubscribed, value, ...props }) => (
  <>
    <label htmlFor="email">Please enter your email address</label>
    <CombiInputContainer>
      <Input
        id="email"
        name="email"
        type="email"
        placeholder="name@example.com"
        value={value}
        aria-label="Email"
        disabled={props.isLoading}
        {...props}
      />

      <Icon
        name="mail"
        thickness={1.25}
        linecap={"butt"}
        color={props.touched && !props.isValid ? "red" : "white"}
      />

      <SubmitButton
        type="submit"
        disabled={props.isLoading || (props.touched && !props.isValid)}
      >
        Subscribe
      </SubmitButton>
    </CombiInputContainer>

    <Message variant={hasError ? "error" : isSubscribed ? "success" : "helper"}>
      {hasError || (!props.isValid && props.touched) ? (
        <Icon
          name="alert-triangle"
          thickness={1.25}
          linecap={"square"}
          color={"red"}
          size={"1.25rem"}
        />
      ) : isSubscribed ? (
        <Icon
          name="check-square"
          thickness={1.25}
          linecap={"square"}
          color={"green"}
          size={"1.25rem"}
        />
      ) : (
        <Icon
          name="info"
          thickness={1.25}
          linecap={"square"}
          color={"white"}
          size={"1.25rem"}
        />
      )}{" "}
      <span>{message}</span>
    </Message>
    {props.preferencesUrl && (
      <a href={props.preferencesUrl}>Manage subscription settings</a>
    )}
  </>
)

const NewsletterFormDisplay = ({
  email,
  message,
  preferencesUrl,
  hasError,
  isSubscribed,
  isLoading,
  isValid,
  touched,
  onBlur,
  onChange,
  onInvalid,
  onSubmit,
}) => (
  <Form onSubmit={onSubmit}>
    <h1>Never miss a Fumble Ducks Issue!</h1>
    <fieldset>
      <legend>Subscribe to the Fumble Ducks Newsletter</legend>
      <EmailInput
        value={email}
        onBlur={onBlur}
        onChange={onChange}
        onInvalid={onInvalid}
        hasError={hasError}
        isSubscribed={isSubscribed}
        message={
          message || "We’ll send you a confirmation request to this address."
        }
        preferencesUrl={preferencesUrl}
        isLoading={isLoading}
        touched={touched}
        isValid={isValid}
        required
      />
      <p>
        <small>
          We treat your data well. Please read our{" "}
          <Link to="/privacy">terms and conditions</Link> for details.
        </small>
      </p>
    </fieldset>
  </Form>
)

export default NewsletterFormDisplay
