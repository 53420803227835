import React from "react"
import { Link, graphql } from "gatsby"

import Brand from "../components/Brand"
import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Container from "../components/Container"
import CardGrid from "../components/CardGrid"
import { IssueSection } from "../components/Issues"
import NewsletterForm from "../components/NewsletterForm"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Brand full />

    <Container as="main">
      <p>
        <Link to="/about/">
          A short mission statement explaining what the fumble ducks blog is
          about.
        </Link>
      </p>

      <IssueSection issues={data.monthlyIssues.edges} />
      <hr />

      <CardGrid entries={data.tags.edges} />

      <NewsletterForm />
    </Container>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }

    posts: allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          fields {
            slug
          }
          title
          excerpt
          slug
          categories {
            acf {
              edition_display
            }
            name
            slug
          }
          tags {
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    issues: allWordpressCategory(
      filter: { parent_element: { slug: { eq: "monthlies" } } }
      sort: { fields: [acf___edition, fields___posts___date], order: DESC }
    ) {
      edges {
        node {
          slug
          name
          description
          count
          acf {
            edition_display
          }
        }
      }
    }

    tags: allWordpressTag(sort: { fields: [name], order: DESC }) {
      edges {
        node {
          slug
          name
          description
          count
          fields {
            slug
          }
        }
      }
    }

    monthlyIssues: allWordpressPost(
      filter: {
        categories: {
          elemMatch: { parent_element: { slug: { eq: "monthlies" } } }
        }
        acf: { is_editorial: { eq: true } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          slug
          date
          title
          excerpt
          categories {
            acf {
              edition_display
            }
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
