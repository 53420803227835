const addToMailchimp = async email => {
  return new Promise((resolve, reject) =>
    fetch("/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json())
      .then(resolve)
      .catch(reject)
  )
}

export default addToMailchimp
